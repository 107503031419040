import { Col, Row } from "antd";
import React, { useEffect } from "react";
import PortfolioCard from "../Cards/PortfolioCard";
import { PortfolioJSONData } from "../../assets/PortfolioJSONData";
import { useState } from "react";
import fireup from '../../assets/Portfolio/fireup.PNG';
import moonhub from "../../assets/Portfolio/moonhub.PNG";
import ftuk from "../../assets/Portfolio/ftuk.png";
import tradingFunds from "../../assets/Portfolio/tradingFunds.png";
import industrialpmr from "../../assets/Portfolio/industrialpmr.PNG";
import RMST from '../../assets/Portfolio/rmst.JPG'
import samsports from "../../assets/Portfolio/samsports.PNG";
import broskeesSS from "../../assets/Portfolio/broskeesSS.png";
import broskeesCarousel from "../../assets/Portfolio/broskeesCarousel.png";
import astralnaut from "../../assets/Portfolio/Capture 3.png";
import astralnautCarousel from "../../assets/Portfolio/astralCarousel.png";
import trackkrr from "../../assets/Portfolio/trackkrrSS.png";
import trackkrrSS from "../../assets/Portfolio/trackkrrSS1.png";
import trackkrrCarousel from "../../assets/Portfolio/trackkrrCarousel.png";
import edme from "../../assets/Portfolio/edmeSS.png";
import edmeCarousel from "../../assets/Portfolio/edmeCarousel.png";
import insightMeter from "../../assets/Portfolio/insightMeterSS.png";
import insightMeterCarousel from "../../assets/Portfolio/insightMeterCarousel.png";
import FloatBoat from "../../assets/Portfolio/FloatBoatSS.png";
import FloatBoatCarousel from "../../assets/Portfolio/floatBoatCarousel.png";
import Titan from "../../assets/Portfolio/TitanSS.png";
import TitanCarousel from "../../assets/Portfolio/titanCarousel.png";
import Hanker from "../../assets/Portfolio/hankerSS.png";
import HankerCarousel from "../../assets/Portfolio/hankerCarousel.png";
import PakGo from '../../assets/pakgo.png';
import Ecommerce from '../../assets/ecommerce.png';
import Screen from '../../assets/screen.png';
import Inbox from '../../assets/inbox.png';
import Quick from '../../assets/quick.png';
import Mojo from '../../assets/mojo.png';
import Right from '../../assets/right.png';
import Food from '../../assets/food.png';
import Nabein from '../../assets/nabein.png';
import Athletix from '../../assets/athletix.png';
import Jogo from '../../assets/jogo.png';
import Penny from '../../assets/penny.png';


function PortfolioData() {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData([
      {
        Image: samsports,
        Image2: samsports,
        title: "Sam Sports",
        category: "",
        link: "https://samsports.io/",
        detail:
          "Welcome to SAM Ultimate Sports, where we offer an unparalleled GM experience that fully immerses you in the world of your favorite sport. As the mastermind behind the scenes, you'll make crucial decisions, shape strategies, and lead your team to victory. Experience the thrill of realistic sports management with SAM Ultimate Sports – your gateway to becoming a true sports strategist. Your ultimate GM adventure starts here.",
      },
      {
        Image: fireup,
        Image2: fireup,
        title: "Fire Up",
        category: "",
        link: "https://www.fireupstar.com/",
        detail:
          "Fire Up",
      },
      {
        Image: moonhub,
        Image2: moonhub,
        title: "Moon Hub",
        category: "",
        link: "https://www.moonhub.ai/",
        detail:
          "Moon Hub",
      },
      {
        Image: RMST,
        Image2: RMST,
        title: "RMST",
        category: "",
        link: "",
        detail:
          "rmst.",
      },
      {
        Image: industrialpmr,
        Image2: industrialpmr,
        title: "Industrial PMR",
        category: "",
        link: "https://industrialpmr.com/",
        detail:
          "IIoT Monitoring Solution For Your Factory. Unleash your factorys true potential with industrial IoT solution.",
      },
      {
        Image: tradingFunds,
        Image2: tradingFunds,
        title: "Trading Funds",
        category: "",
        link: "",
        detail:
          "tradingFunds",
      },
      {
        Image: ftuk,
        Image2: ftuk,
        title: "FTUK",
        category: "",
        link: "",
        detail:
          "ftuk",
      },
      {
        Image: broskeesSS,
        Image2: broskeesCarousel,
        title: "Broskees",
        category: "NFT",
        link: "https://www.broskees.io/",
        detail:
          "The Broskees NFT is a small collection of 1691 Broskees on the Ethereum blockchain. EACH broskee NFT serves as a pass and gets you",
      },
      {
        Image: astralnaut,
        Image2: astralnautCarousel,
        title: "AstralnautX",
        category: "",
        link: "https://astralnaut.designitic.com/",
        detail:
          "AstralX is a grand undertaking to create a game that combines the emergent tech stacks of crypto, distributed networking made possible via blockchain, and the power of next-gen technologies that are now available for game developers. Players will enter a massive adventure played across the stars where player choices matter and actions impact how the game’s universe progresses",
      },
      {
        Image: trackkrrSS,
        Image2: trackkrrCarousel,
        title: "Trackkrr",
        category: "",
        link: "https://trackkrr.com/",
      },
      {
        Image: edme,
        Image2: edmeCarousel,
        title: "Edme",
        category: "",
        link: "http://edme.media/",
        detail:
          "Edme is an online learning platform that offers courses and certifications. Users can access a wide range of courses in various subjects, including computer science, business, data science, and more. The platform offers both free and paid courses, and users can earn certificates for completing them. In summary, Edme is an online learning platform that offers a wide range of courses and certifications in various subjects, allowing users to learn at their own pace and on their own schedule.",
      },
      {
        Image: insightMeter,
        Image2: insightMeterCarousel,
        title: "Insight Meter",
        category: "",
        link: "http://insightmeter.com/",
        detail:
          "From smarter software to specialized agent training, our now or atlest tomorrow ultra good ok nice go away multi-channel an expertise encourages engagement that increases even satisfaction and drives conversion",
      },
      {
        Image: FloatBoat,
        Image2: FloatBoatCarousel,
        title: "Float Boat",
        category: "",
        link: "https://floatboat.com/",
        detail:
          "The FloatBoat crew will help you embark on new adventures by connecting you with the right vessel and co-owners. We manage the corporate logistics, scheduling, operations and maintenance of your co-owned yacht so you can focus on planning your voyage. Get on board today, the open seas await!",
      },
      {
        Image: Titan,
        Image2: TitanCarousel,
        title: "Titan",
        category: "",
        link: "https://titanmms.com/",
        detail:
          "Titan is a Computerized Maintenance Management System, that helps organizations manage their maintenance operations. Some key features of Titan are Work Order Management, Equipment Management, Inventory Management, Preventive Maintenance and Reporting and Analytics. In summary, Titan is a powerful tool that helps organizations streamline their maintenance operations, improve equipment reliability, and reduce maintenance costs",
      },
      {
        Image: Hanker,
        Image2: HankerCarousel,
        title: "Hanker",
        category: "",
        link: "https://hanker.com.pk/",
        detail:
          "Hanker utilizes big data and artificial intelligence to improve sales and marketing efforts, as well as credit assessment and risk management. Some benefits of using Hanker are, Improved Customer Insights, Increased Sales, Better Marketing and Improved Credit Assessment",
      },
      {
        Image: PakGo,
        title: "Pak Go",
        detail: "A complete logistics solution for Marine Group of companies for management of shipments via truck and train. Consumer booking app for tracking shipments. Driver app to pickup and dropoff shipments with respect to cargo. Carrier Management app for assigning bookings to drivers."
      },
      {
        Image: Ecommerce,
        title: "E-Commerce",
        detail: "A multi-tenant ecommerce SaaS platform to serve all the needs of a modern shopping platform. Easy & rapidly configurable on client needs. Developed on cutting edge technologies."
      },
      {
        Image: Screen,
        title: "ScreenDiary",
        detail: "Easily track, log & manage projects with ScreenDiary. Keeping you productive in a world of distraction by Effectively Managing in this modern era."
      },
      {
        Image: Inbox,
        title: "Inbox.Rent",
        detail: "InboxRent is an web application where Landlords monitor tenants also manage the apartment booking & finance tracking via application. You can Advertise your property with Inbox.Rent and other major sites for free! Save Time, Grow Your Rental Portfolio."
      },
      {
        Image: Quick,
        title: "Quick Delivery",
        detail: "QuickDelivery is an online food delivery marketplace that connects restaurants to the public, enabling foodies to digitally place orders to their favorite restaurants."
      },
      {
        Image: Mojo,
        title: "Mojo",
        detail: "MoJo is a full-feature companion app for connectivity to venues, transit, travel, rewards, retail, entertainment, sports and journey management tools. Mojo also integrates over 3000 international transit agencies including trains and buses as well as ride shares and bike shares."
      },
      {
        Image: Right,
        title: "Right Transport User",
        detail: "Right transport is a three in one app for booking rides within city, Bus tickets booking and 2D tickets booking. Our marketplace of services and listings help move people, efficiently, saving time and money."
      },
      {
        Image: Food,
        title: "FoodChoo",
        detail: "FoodChoo is an online innovative on demand food delivery platform aims to provide best experience from their client first mobile app made on state of the art technologies."
      },
      {
        Image: Nabein,
        title: "Nabeinn Foods",
        detail: "nabeinn foods is all about homemade food. With nabeinn foods, you can order delicious meals from locals in your neighbourhood. Simply use the app to browse local meals and get all the details you want. Order the food you like, conveniently pay on the app and choose pick up or delivery."
      },
      {
        Image: Athletix,
        title: "AthletiX Training",
        detail: "A comprehensive training system with an emphasis on proper biomechanical movements and neurological conditioning to enhance athletic ability by increase speed, power and proprioception. By developing joint and tendon resilience you become more resistant to injury.. Join AthletiX for your own customized athletic training program to follow step by step."
      },
      {
        Image: Jogo,
        title: "Jogo",
        detail: "JOGO aims to revolutionize the world of football and help you reach your full potential. By bringing data science, AI and machine learning (ML) together with tailor-made exercises and assignments."
      },
      {
        Image: Penny,
        title: "Penny Pinch",
        detail: "A convenient, easy, and rewarding way to shop and send money. Save money on your purchases through discounts while also benefiting from an impressive loyalty program. Also, send and receive money within seconds to anyone who has a Penny Pinch account."
      },
    ]);
  }, []);

  const companyChangeHandler = (value) => {
    if (value === "BRANDING") {
      const data = PortfolioJSONData.filter(
        (data) => data.company === "BRANDING"
      );
      setData(data);
      console.log("DATA", data);
    }
    if (value === "MOTION") {
      const data = PortfolioJSONData.filter(
        (data) => data.company === "MOTION"
      );
      setData(data);
      console.log("DATA", data);
    }
    if (value === "PHOTOGRAPHY") {
      const data = PortfolioJSONData.filter(
        (data) => data.company === "PHOTOGRAPHY"
      );
      setData(data);
      console.log("DATA", data);
    }
    if (value === "ALL") {
      setData(PortfolioJSONData);
      console.log("DATA", data);
    }
  };

  return (
    <div className="parent_container">
      <div className="PortfolioData">
        {/* <div className="filter">
          <p onClick={() => companyChangeHandler("ALL")}>ALL</p>
          <div>
            <p onClick={() => companyChangeHandler("BRANDING")}>BRANDING</p>
            <p onClick={() => companyChangeHandler("MOTION")}>MOTION</p>
            <p onClick={() => companyChangeHandler("PHOTOGRAPHY")}>
              PHOTOGRAPHY
            </p>
          </div>
        </div> */}
        <Row gutter={[40, 40]} style={{ marginTop: "30px" }}>
          {data.map((data) => {
            return (
              <Col xxl={6} xl={8} lg={8} md={12} sm={12} xs={24}>
                <PortfolioCard
                  img={data.Image}
                  carouselImg={data.Image2}
                  title={data.title}
                  category={data.company}
                  link={data.link}
                  detail={data.detail}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
}

export default PortfolioData;
